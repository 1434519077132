$(function(){
  if($('#stations').length > 0) {
    loadStations(100);
  }
});

const loadStations = (offset) => {
  $.ajax({
    method: 'get',
    url: '/api/v1/stations',
    data: { offset: offset }
  }).done(function(data) {
    if (data.length > 0) {
      data.forEach(station => {
        $('#stations').append(`
          <tr>
            <td>${station.id}</td>
            <td>${station.sat_uid_name}</td>
            <td>${station.default_name}</td>
            <td>${station.station_code}</td>
            <td>${station.additional_station_names}</td>
            <td>${station.latitude}</td>
            <td>${station.longitude}</td>
            <td><a href="/stations/${station.id}">Show</td>
            <td><a href="/stations/${station.id}/edit">Edit</td>
            <td><a data-confirm="Are you sure?" rel="nofollow" data-method="delete" href="/stations/${station.id}">Destroy</a></td>
          </tr>
        `);
      });
      setTimeout(() => loadStations(offset + 100), 100);
    }
  });
}
